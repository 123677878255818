import { ReactElement, ReactNode } from "react";
export const VEHICLES_LOADING = "VEHICLES_LOADING";
export const VEHICLES_FAILURE = "VEHICLES_FAILURE";
export const VEHICLES_SUCCESS = "VEHICLES_SUCCESS";

export type Vehicles = {
  id: string;
  AssetNumber: string;
  MCUFirmwareVersion: string;
  values: Array<VehicleData>;
};

export type VehicleData = {
  Date: string;
  DeviceName: string;
  StealthBatteryVolts: number;
  SolarAmps: number;
  SystemTemperature: number;
  MCUString: string;
  StealthBatteryAmps: number;
  ShorelineVolts: number;
  Timer: number;
};

export interface VehicleLoading {
  type: typeof VEHICLES_LOADING;
}

export interface VehicleFailure {
  type: typeof VEHICLES_FAILURE;
}

export interface VehicleSuccess {
  type: typeof VEHICLES_SUCCESS;
  payload: Array<Vehicles>;
}

export type VehicleDispatchTypes =
  | VehicleLoading
  | VehicleFailure
  | VehicleSuccess;
